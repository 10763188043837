export const formatYMDHIS = (ymdhis, type = 'H:i') => {
    if (ymdhis === null) return '';
    ymdhis = ymdhis.toString();
    const year = ymdhis.substr(0, 4);
    const month = ymdhis.substr(4, 2);
    const date = ymdhis.substr(6, 2);
    const hour = ymdhis.substr(8, 2);
    const min = ymdhis.substr(10, 2);
    const sec = ymdhis.substr(12, 2);
    if (type === 'date')
        return new Date(hour + ':' + min + ':' + sec + ' ' + month + '/' + date + '/' + year);
    return type.replace(/H/g, hour).replace(/i/g, min)
        .replace(/s/g, sec).replace(/Y/g, year)
        .replace(/m/g, month).replace(/d/g, date);
}
export const formatObject = (dt, type = 'H:i') => {
    if(!('getDate' in dt)) return '';
    const date = ('0' + dt.getDate()).substr(-2);
    const month = ('0' + (dt.getMonth() + 1)).substr(-2);
    const year = dt.getFullYear();
    const hour = ('0' + dt.getHours()).substr(-2);
    const min = ('0' + dt.getMinutes()).substr(-2);
    const sec = ('0' + dt.getSeconds()).substr(-2);
    return type.replace(/H/g, hour).replace(/i/g, min)
        .replace(/s/g, sec).replace(/Y/g, year)
        .replace(/m/g, month).replace(/d/g, date);
}

export const dateEqual = (dt1, dt2) => {
    const fdt1 = parseInt(formatObject(dt1, 'YmdHis'));
    const fdt2 = parseInt(formatObject(dt2, 'YmdHis'));
    if (fdt1 === fdt2) return true;
    return false;
}
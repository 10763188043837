import React from 'react';
import getLanguage from '../conf/getLanguage';

class Footer extends React.Component {
    render() {
        const lg=getLanguage('footerLanguage');
        const appName = process.env.REACT_APP_NAME;
        const firmName = process.env.REACT_APP_FIRM;
        const dsc = lg.cre.replace('[__firm__]', firmName).replace('[__brand__]', appName).replace('[__year__]', (new Date()).getFullYear());
        return (
            <footer className="footer">
                <div>
                    <span>{dsc}</span>
                </div>
            </footer>
        );
    }
}

export default Footer;
import React from "react";
import cLogin from "../conf/checker";
import NonAuthenticatedRoutes from "./NonAuthenticatedRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

const Routes = () => {
    if (cLogin()) return <AuthenticatedRoutes />;
    else return <NonAuthenticatedRoutes />;
};

export default Routes;
import axios from "axios";
import qs from "querystring";
import { Notification } from "rsuite";
import '../assets/css/dashforge.auth.css';
import React from "react";
import getLanguage from "../conf/getLanguage";
import Button from '../components/_common/Button';
import $ from "jquery";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }
    render() {
        let frmData = {};
        const lg = getLanguage('loginLanguage');
        const serviceURL = process.env.REACT_APP_SERVICE;
        const onSubmit = async (e) => {
            e.preventDefault();
            this.setState({ loading: true })
            await axios({
                method: "POST",
                url: `${serviceURL}user/login`,
                data: qs.stringify(frmData),
            }).then((res) => {
                if (!res.data.status) {
                    Notification.error({
                        title: lg.error,
                        description: lg.userNotFound
                    });
                    return 0;
                }
                let usr = res.data.Result;
                localStorage.setItem('user', usr.Name);
                localStorage.setItem('Token', usr.Token);
                localStorage.setItem('User', JSON.stringify(usr));
                window.location = '/';
            }).finally(() => this.setState({ loading: false }))
        }
        const onChange = (e) => {
            frmData[e.target.name] = e.target.value;
        }
        const sendRequest = (e) => {
            e.preventDefault();
            const frm_dat = new FormData(e.target);
            this.setState({ loading: true })
            axios({
                method: "POST",
                url: `${serviceURL}user/request`,
                data: frm_dat,
            }).then((res) => {
                if (!res.data.status) {
                    Notification.error({
                        title: lg.error,
                        description: lg[res.data.Message]
                    });
                    return 0;
                }
                Notification.success({
                    title: "Success",
                    description: "We stored your request. Our admins will contact you soon. Please, stay in touch"
                });
                $(e.target).find('input').val('');
            }).finally(() => this.setState({ loading: false }))

        }
        return (
            <div class="content content-fixed content-auth-alt">
                <div class="container">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div class="wd-600 mx-wd-100p-f mg-b-30">

                            <div className="section mg-b-50">
                                <p className="text-center tx-color-03 mg-b-40">We are fascinated by blockchain technology and wanted to build our own platform and realized all our friends wanted to participate as well. We are all strong believers in the future of digital currencies, and we are excited to be part of a growing community.</p>
                                <form onSubmit={onSubmit} className="form-inline justify-content-center">
                                    <div className="form-group mr-2">
                                        <input type="email" className="form-control" placeholder="Your Email" name="Email" onChange={onChange} style={{ width: '178px' }} />
                                    </div>
                                    <div className="form-group mr-2">
                                        <input type="password" className="form-control" placeholder="Your Password" name="Password" onChange={onChange} style={{ width: '178px' }} />
                                    </div>
                                    <Button style={{ width: '178px', marginRight: '8px', }} className="btn-dark" type="submit" loading={this.state.loading}>Sign In</Button>
                                </form>
                            </div>
                            <hr />
                            <div className="section mg-t-50">
                                <h4 className="text-center tx-20 tx-sm-24">Request Your Access Code</h4>
                                <p className="text-center tx-color-03 mg-b-40">Zebramine is a blockchain network for FRIENDS. To be a part of our network, you should be invited by a friend already in our network.</p>
                                <form onSubmit={sendRequest} className="form-inline justify-content-center">
                                    <div className="form-group mr-2 mb-2">
                                        <input type="text" maxLength="255" className="form-control" placeholder="Name" name="name" style={{ width: '178px' }} />
                                    </div>
                                    <div className="form-group mr-2 mb-2">
                                        <input type="email" maxLength="255" className="form-control" placeholder="Email" name="email" style={{ width: '178px' }} />
                                    </div>
                                    <div className="form-group mr-2 mb-2">
                                        <input type="text" maxLength="50" className="form-control" placeholder="Phone" name="phone" style={{ width: '178px' }} />
                                    </div>
                                    <div className="form-group mr-2 mb-2">
                                        <input type="text" maxLength="255" className="form-control" placeholder="Referred By" name="referrer" style={{ width: '178px' }} />
                                    </div>
                                    <div className="form-group mr-2 mb-2">
                                        <input type="text" maxLength="255" className="form-control" placeholder="Linkedin ID" name="linkedin_id" style={{ width: '178px' }} />
                                    </div>
                                    <Button style={{ width: '178px', marginRight: '8px', marginTop: '-8px' }} className="btn-danger" type="submit" loading={this.state.loading}>Request</Button>
                                    <span class="tx-12 tx-color-03 mg-t-30">You can also contact us by <a href="mailto:info@zebramine.com">info@zebramine.com</a></span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;
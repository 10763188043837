export const formatBytes = (bytes, decimals = 3) => {
    if (bytes === null) return '';
    bytes = parseInt(bytes);
    if (bytes === 0) return '0 B';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const formatMeasure = (val) => {
    val = parseInt(val);
    const str = val + '';
    const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const tm = parseInt((str.length - 1) / 3);
    return val / Math.pow(1000, tm) + ' ' + sizes[tm];
}
export const formatNumber = (num, frc = 10, dl1 = '.', dl2 = ',') => {
    var st = (parseInt(num * Math.pow(10, frc)) / Math.pow(10, frc)) + '';
    var ar = st.substr(0, st.lastIndexOf('.'));
    if (ar !== '') {
        const arle = parseInt(ar.length / 3);
        for (let a = 0; a < arle; a++) {
            const y = -((a + 1) * 3 + a);
            if (ar.length + y === 0) continue;
            ar = ar.substr(0, ar.length + y) + dl2 + ar.substr(y);
        }
        st = ar + dl1 + st.substr(st.lastIndexOf('.') + 1, st.length);
    }
    return st
}
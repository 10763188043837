import Waves from 'node-waves';
import React from "react";
import PropTypes from 'prop-types';
import { Dropdown, Button, Icon } from 'rsuite';
import { Name, Surname, Guid } from '../functions/userInfos';

class Navbar extends React.Component {
    render() {
        return (
            <header className="navbar navbar-header navbar-header-fixed">
                <a href="#" id="mainMenuOpen" className="burger-menu"><i data-feather="menu"></i></a>
                <div className="navbar-brand">
                    <a href="/" className="df-logo">
                        <img src="/logo01.png" style={{ maxHeight: '38px' }} />
                    </a>
                </div>
                <div id="navbarMenu" className="navbar-menu-wrapper">
                    <div className="navbar-menu-header">
                        <a href="/" className="df-logo">
                            <img src="/logo02.png" style={{ maxHeight: '38px' }} />
                        </a>
                        <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
                    </div>
                    {this.getNavbarLinks()}
                </div>
                {this.getNavbarRight()}
            </header>
        );
    }
    signOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('Token');
        window.location.href = '/login';
    }
    getNavbarRight() {
        if (!this.props.loggedIn) return null;
        return (
            <div className="navbar-right">
                <Dropdown
                    renderTitle={() => {
                        return (<>
                            <span style={{ fontSize: '16px', marginTop: '4px', display: 'block', float: 'left' }}>{Name + ' ' + Surname}</span>
                            <div className="avatar avatar-sm float-right mg-l-10">
                                <img src="https://via.placeholder.com/500" className="rounded-circle" alt="" />
                            </div>
                        </>);
                    }}
                    placement="bottomEnd"
                    className="dropdown-profile"
                >
                    <Dropdown.Item>
                        <Button onClick={this.signOut}>
                            <Icon icon='sign-out' />Sign Out
                        </Button>
                    </Dropdown.Item>
                </Dropdown>
            </div>
        );
    }
    getNavbarLinks() {
        if (!this.props.loggedIn) return null;
        var navs = [/*
            ['/', 'Dashboard'],
            ['/businesses', 'Search'],
            ['/favorites', 'Favorites'],
            ['/comments', 'Reviews']*/
        ];
        if(Guid==='C08E7ED3-DF7B-BCF8-0C53-25422FE9F694'){
            navs.push(['/', 'Dashboard']);
            navs.push(['/admin-board', 'Payouts & Expenses']);
            navs.push(['/incomes', 'Incomes']);
        }
        return (
            <ul className="nav navbar-menu">
                {
                    navs.map((el, key) => (
                        <li className="nav-item" key={key}>
                            <a
                                href={el[0]}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.linkClick(el[0])
                                }}
                            >{el[1]}</a>
                        </li>
                    ))
                }
            </ul>
        )
    }
    componentDidMount() {
        Waves.init();
    }
}

Navbar.propTypes = {
    onLangChange: PropTypes.func,
    linkClick: PropTypes.func,
    loggedIn: PropTypes.bool
}

export default Navbar;
const usercr = localStorage.getItem('User');
var usp={Photo:null, Name:null, Surname:null, email:null, Guid: null};
var uInf=null;
if (usercr !== null) {
    uInf = JSON.parse(usercr);
    const imagesLink = process.env.REACT_APP_IMAGES_LINK;
    const placeHolderLink = process.env.REACT_APP_PLACEHOLDER_LINK;
    usp.Photo = (uInf.Photo === null) ?
        placeHolderLink + uInf.Name.substr(0, 1) + uInf.Surname.substr(0, 1) :
        imagesLink + '50x50/' + uInf.Photo;
    usp.Name = uInf.Name;
    usp.Surname = uInf.Surname;
    usp.Email = uInf.email;
    usp.Guid= uInf.Guid;
}
export const userInfos=uInf;
export const Photo=usp.Photo;
export const Name=usp.Name;
export const Surname=usp.Surname;
export const Email=usp.Email;
export const Guid=usp.Guid;
import React from "react";
import 'simplebar/dist/simplebar.min.css';
import Loader from "../components/_common/Loader";
import sendReq from "../functions/sendReq";
import MiniTable from "../components/_common/MiniTable.jsx";
import { formatMeasure, formatNumber } from "../functions/format";
import { formatYMDHIS } from "../functions/formatDate";
import PayoutChart from "../components/dashboard/PayoutChart";
import ManagementFees from "../components/dashboard/ManagementFees";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            nums: false,
            cr: 0
        }
    }
    render() {
        return (
            <>
                {this.numsRender()}
            </>
        );
    }
    getNums() {
        sendReq('dashboard/nums').then((r) => {
            r.single.Hashrate = formatMeasure(r.single.Hashrate) + 'H';
            this.nums = r;
            this.setState({ nums: true })
            setTimeout(() => {
                this.getNums();
            }, 180000);
        })
    }
    numsRender() {
        if (this.state.nums === false)
            return (
                <div className="row">
                    <div className="col-12" style={{ height: '150px', position: 'relative' }}>
                        <Loader loading={true} height="100%" />
                    </div>
                </div>
            );
        const single = this.nums.single;
        const prices = this.nums.prices;
        const cr = this.nums.currencies;
        const kys = Object.keys(cr);
        const fct = cr[kys[this.state.cr]];
        const cur = kys[this.state.cr];
        const onCurChange = () => {
            const pls = this.state.cr + 1;
            this.setState({ cr: (pls in kys) ? pls : 0 })
        }
        return (
            <>
                <div className="row">
                    <div className="col-12"><p className="tx-color-03 tx-semibold mg-b-0 mg-t-0 tx-12 text-right">Last Update : {formatYMDHIS(this.nums.date, 'm/d/Y H:i')}</p></div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-10">
                        <div className="card card-body bg-warning-light" style={{ overflow: 'hidden' }}>
                            <i className="cf cf-btc tx-orange" style={{ transform: 'rotate(-14deg)', position: 'absolute', right: '-23px', top: '-8px', fontSize: '105px', opacity: '0.5', zIndex: 0 }}></i>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8 tx-orange">BTC</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h4 className="tx-normal tx-rubik mg-b-4 mg-r-5 lh-1">${formatNumber(cr['USD'] / cr['BTC'], 2)}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-10">
                        <div className="card card-body bg-indigo-light" style={{ overflow: 'hidden' }}>
                            <i className="cf cf-eth" style={{ transform: 'rotate(-14deg)', position: 'absolute', right: '-23px', top: '-8px', fontSize: '105px', opacity: '0.8', zIndex: 0 }}></i>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8 tx-purple">ETH</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h4 className="tx-normal tx-rubik mg-b-4 mg-r-5 lh-1">${formatNumber(cr['USD'], 2)}</h4>
                            </div>
                        </div>
                    </div>
                    {
                        Object.keys(single).map((e, key) => (
                            <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-10" key={key}>
                                <div className="card card-body">
                                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">{e}</h6>
                                    <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                        <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{single[e]}</h3>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        Object.keys(prices).map((e, key) => {
                            const prc = formatNumber(prices[e] * fct, 4);
                            return (
                                <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-10" key={key}>
                                    <div className="card card-body crd-multi" style={{ cursor: 'pointer' }}>
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
                                            {e}
                                        </h6>
                                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{prc + ' ' + cur}</h3>
                                        </div>
                                        <div onClick={onCurChange} style={{ cursor: 'pointer', fontSize: '20px', position: 'absolute', top: '12px', right: '15px' }}>
                                            <i className="icon ion-ios-swap tx-pink"></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-10">
                        <div className="card card-body bg-success-light" style={{ overflow: 'hidden' }}>
                            <i className="fa fa-coins tx-success" style={{ transform: 'rotate(-14deg)', position: 'absolute', right: '-9px', top: '3px', fontSize: '89px', opacity: '0.5', zIndex: 0 }}></i>
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8 tx-success">Total Payout</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h4 className="tx-normal tx-rubik mg-b-4 mg-r-5 lh-1">{formatNumber(this.nums.payout_sum, 4) + ' ETH'}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mg-t-10">
                    <ManagementFees fct={fct} cur={cur} />
                </div>
                <div className="row mg-t-10">
                    <div className="col-12">
                        <PayoutChart />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mg-t-10">
                        <MiniTable
                            title="Pool Awards"
                            service="dashboard/poolAwards"
                            showEmpty={true}
                            columns={[
                                {
                                    title: "Date", width: "60", fn: (a) => {
                                        return formatYMDHIS(a.Date, 'm/d/Y H:i')
                                    }
                                },
                                {
                                    title: "Amount", width: "40", cl: 'text-right', fn: (a) => {
                                        return (parseInt(a.Value * fct / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ' + cur
                                    }
                                },
                                {
                                    title: "Tx Hash", width: "40", col: "TxHash"
                                }
                            ]}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mg-t-10">
                        <MiniTable
                            title="Expenses"
                            service="dashboard/expenses"
                            showEmpty={true}
                            columns={[
                                {
                                    title: "Date", width: "60", fn: (a) => {
                                        return formatYMDHIS(a.Date, 'm/d/Y H:i')
                                    }
                                },
                                {
                                    title: "ETH / USD", width: "30", col: 'USD'
                                },
                                {
                                    title: "Amount", width: "40", cl: 'text-right', fn: (a) => ((parseInt(a.Value * fct / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ' + cur)
                                }
                            ]}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mg-t-10">
                        <MiniTable
                            title="Payouts"
                            service="dashboard/payouts"
                            showEmpty={true}
                            columns={[
                                {
                                    title: "Date", width: "60", fn: (a) => {
                                        return formatYMDHIS(a.Date, 'm/d/Y H:i')
                                    }
                                },
                                {
                                    title: "ETH / USD", width: "30", col: 'USD'
                                },
                                {
                                    title: "Amount", width: "40", cl: 'text-right', fn: (a) => ((parseInt(a.Value * fct / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ' + cur)
                                }
                            ]}
                        />
                    </div>
                </div>
            </>
        );
    }
    componentDidMount() {
        this.getNums();
    }
}

export default Dashboard;
import React from "react";
import PropTypes from 'prop-types';
import Navbar from "./Navbar";
import Footer from "./Footer";

class Panel extends React.Component {
    constructor(props) {
        super(props);
        let language = localStorage.getItem('language');
        if (language == null) {
            language = 'en-us';
            localStorage.setItem('language', language);
        }
        this.state = {
            language: language,
            nmDate: new Date()
        }
    }
    render() {
        return (
            <>
                <Navbar
                    onLangChange={this.props.onLangChange}
                    linkClick={this.props.linkClick}
                    loggedIn={this.props.loggedIn}
                />
                {
                    (this.props.loggedIn) ? (
                        <div className="content content-fixed bd-b">
                            {this.props.children}
                        </div>
                    ) : (
                        <>
                            {this.props.children}
                        </>
                    )
                }
                <Footer />
            </>
        );
    }
    componentDidMount() {
    }
}

Panel.propTypes = {
    children: PropTypes.element,
    onLangChange: PropTypes.func,
    linkClick: PropTypes.func,
    loggedIn: PropTypes.bool
}
Panel.defaultProps = {
    children: <></>,
    onLangChange: () => { },
    linkClick: () => { },
    loggedIn: false
}

export default Panel;
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'rsuite/dist/styles/rsuite-default.css';
import './assets/lib/bootstrap/css/bootstrap.min.css';
import './assets/lib/ionicons/css/ionicons.min.css';
import './assets/lib/cryptofont/css/cryptofont.min.css';
import './assets/lib/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/dashforge.dashboard.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render( <
    React.StrictMode >
    <App / >
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React from "react";
import sendReq from "../../functions/sendReq";
import { userInfos } from "../../functions/userInfos";
import { PanelGroup, Panel } from "rsuite";
import { formatNumber } from "../../functions/format";
import { formatYMDHIS } from "../../functions/formatDate";
import SimpleBar from "simplebar-react";
import Loader from "../_common/Loader";

class ManagementFees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            date: new Date(),
            loading: true
        }
        this.data = [];
    }
    render() {
        if (userInfos.ID !== 4) return null;
        if (this.state.loading) return (
            <div className="col-12">
                <div className="card card-body" style={{ "height": '550px', 'position': 'relative' }}>
                    <Loader height="100%" loading={true} />
                </div>
            </div>
        );
        const fct = this.props.fct;
        const cur = this.props.cur;
        var gt1 = 0, gt2 = 0, gt3 = 0;
        return (<>
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card">
                    <div class="card-header d-flex align-items-center justify-content-between bg-primary">
                        <h6 class="mg-b-0 text-white">Fees</h6>
                    </div>
                    <div className="card-body" style={{ "height": '550px', 'position': 'relative' }}>
                        <SimpleBar style={{ maxHeight: '100%' }}>
                            <PanelGroup accordion bordered>
                                {
                                    this.data.map((e, key) => {
                                        if (e.Fees.toString() === '') return;
                                        var v_t = 0;
                                        const ac_in = (
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th class="text-right">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {e.Fees.map((e1, key1) => {
                                                        const vl = parseInt(e1.Value) * fct;
                                                        v_t += vl;
                                                        return (
                                                            <tr key={key1}>
                                                                <td>{formatYMDHIS(e1.Date, 'd/m/Y')}</td>
                                                                <td class="text-right">{`${parseInt(vl / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        )
                                        gt1 += v_t;
                                        return (
                                            <Panel header={`${e.Name} ${e.Surname} ${parseInt(v_t / Math.pow(10, 14)) / Math.pow(10, 4)} ${cur}`} key={key}>
                                                <div>{ac_in}</div>
                                            </Panel>
                                        );
                                    })
                                }
                            </PanelGroup>
                            <p className="text-primary text-right mg-t-20 mg-r-10">Total : {`${parseInt(gt1 / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</p>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card">
                    <div class="card-header d-flex align-items-center justify-content-between bg-primary">
                        <h6 class="mg-b-0 text-white">Incomes</h6>
                    </div>
                    <div className="card-body" style={{ "height": '550px', 'position': 'relative' }}>
                        <SimpleBar style={{ maxHeight: '100%' }}>
                            <PanelGroup accordion bordered>
                                {
                                    this.data.map((e, key) => {
                                        if (e.Incomes.toString() === '') return;
                                        var v_t = 0;
                                        const ac_in = (
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th class="text-right">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {e.Incomes.map((e1, key1) => {
                                                        const vl = parseInt(e1.Value) * fct;
                                                        v_t += vl;
                                                        return (
                                                            <tr key={key1}>
                                                                <td>{formatYMDHIS(e1.Date, 'd/m/Y')}</td>
                                                                <td class="text-right">{`${parseInt(vl / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        )
                                        gt2 += v_t;
                                        return (
                                            <Panel header={`${e.Name} ${e.Surname} ${parseInt(v_t / Math.pow(10, 14)) / Math.pow(10, 4)} ${cur}`} key={key}>
                                                <div>{ac_in}</div>
                                            </Panel>
                                        );
                                    })
                                }
                            </PanelGroup>
                            <p className="text-primary text-right mg-t-20 mg-r-10">Total : {`${parseInt(gt2 / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</p>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card">
                    <div class="card-header d-flex align-items-center justify-content-between bg-primary">
                        <h6 class="mg-b-0 text-white">Estimated</h6>
                    </div>
                    <div className="card-body" style={{ "height": '550px', 'position': 'relative' }}>
                        <SimpleBar style={{ maxHeight: '100%' }}>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th class="text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.data.map((e, key) => {
                                            const vl = e.Unp * fct;
                                            gt3 += vl;
                                            return (
                                                <tr key={key}>
                                                    <td>{`${e.Name} ${e.Surname}`}</td>
                                                    <td class="text-right">{`${parseInt(vl / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            <p className="text-primary text-right mg-r-10">Total : {`${parseInt(gt3 / Math.pow(10, 8)) / Math.pow(10, 10)} ${cur}`}</p>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </>);
    }
    componentDidMount() {
        if (userInfos.ID !== 4) return;
        sendReq('dashboard/listFees').then((r) => {
            this.data = r;
            this.setState({ loading: false })
        })
    }
}

export default ManagementFees;
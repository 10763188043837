import './App.css';
import './assets/css/dashforge.css';
import Routes from './routes';

function App() {
  return (
    <Routes />
  );
}

export default App;

import React from 'react';
import { Notification } from 'rsuite';
import { DatePicker } from 'rsuite';
import sendReq from '../../functions/sendReq';
import { formatObject, formatYMDHIS } from '../../functions/formatDate';

class Row extends React.Component {

    constructor(props) {
        super(props)
        this.value = "";
        this.fee = "";
        this.date = "";
        this.new_hr = false;
        this.action_date = false;
        this.state = {
            selectValue: 'ETH'
        }
    }
    render() {
        const key = this.props.key;
        const u = this.props.user;
        const onChange = (e) => {
            this.value = e.target.value
        }
        const onChange2 = (e) => {
            this.date = formatObject(e, "YmdHis");
        }
        const feeChange = (e) => this.fee = e.target.value;
        const onClick = (e) => {
            this.props.callback(true);
            sendReq('users/update', {
                hr: this.value,
                date: this.date,
                id: u.ID,
                fee: this.fee,
                coin: this.state.selectValue
            }, false, { eLang: this.props.lang }).then(() => {
                Notification.success({
                    title: "Success!",
                    description: "Operation Success!"
                })
                this.props.getUserProps();
            }).finally(() => this.props.callback(false))
        }
        const selectChange = (e) => {
            this.setState({ selectValue: e.target.value });
        }
        const cancelClick = (e) => {
            this.props.callback(true);
            sendReq('users/remove_update', { id: u.ID }).then(() => {
                Notification.success({
                    title: "Success!",
                    description: "Operation Success!"
                })
                this.props.getUserProps();
            })
        }
        if (u.action_date === false && u.new_hr === false) {
            return (
                <tr>
                    <td>{u.Name} {u.Surname}</td>
                    <td>{u.HR}</td>
                    <td> <div className="form-group mr-2 mb-2">
                        <input type="text" maxLength="255" className="form-control" name="name" style={{ width: '178px' }} onChange={onChange} />
                    </div></td>
                    <td><div className="form-group mr-2 mb-2">
                        <DatePicker style={{ width: 178, marginTop: '0px' }} onChange={onChange2} />
                    </div></td>
                    <td><div><select style={{ width: '178px', height: '35px', marginRight: '8px', marginTop: '3px', borderRadius: '0.3rem', borderColor: 'gray' }} value={this.state.value} onChange={selectChange}>
                        <option value="ETH">Ethereum</option>
                    </select></div></td>
                    <td> <div className="form-group mr-2 mb-2">
                        <input type="number" className="form-control" name="name" style={{ width: '178px' }} onChange={feeChange} />
                    </div></td>
                    <td> <button style={{ width: '178px', height: '35px', marginRight: '8px', marginTop: '3px', borderRadius: '0.2rem' }} className="btn-danger" type="submit" onClick={onClick} >Request</button></td>
                </tr>
            )
        }
        else {
            return (

                <tr>
                    <td>{u.Name} {u.Surname}</td>
                    <td>{u.HR}</td>
                    <td>{u.new_hr}</td>
                    <td>{formatYMDHIS(u.action_date, 'm/d/Y H:i')}</td>
                    <td>ETH</td>
                    <td>{u.new_man_fee}</td>
                    <td> <button style={{ width: '178px', height: '35px', marginRight: '8px', marginTop: '3px', borderRadius: '0.2rem' }} className="btn-danger" type="submit" onClick={cancelClick}>Cancel</button></td>
                </tr>
            )
        }
    }


}
export default Row;
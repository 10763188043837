import React from "react";
import { Loader as RSLoader } from "rsuite";
import PropTypes from 'prop-types';

class Loader extends React.Component {
    render() {
        if (!this.props.loading) return null;
        const style = { height: this.props.height, position: 'absolute', width: '100%', left: 0, top: 0, zIndex: 100 };
        if (this.props.backdrop) style['backgroundColor'] = '#0000000f';
        return (
            <div style={style}>
                <RSLoader size="lg" center />
            </div>
        );
    }
}
Loader.propTypes = {
    loading: PropTypes.bool,
    backdrop: PropTypes.bool,
    height: PropTypes.string
}
Loader.defaultProps = {
    loading: false,
    backdrop: false,
    height: 'calc(100vh - 70px - 1.25rem - 60px)'
}
export default Loader;
import React from 'react';
import PropTypes from 'prop-types';
import rand from '../../functions/rand';
import { Icon } from 'rsuite';

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.id = `btn-${rand(1111, 9999)}`;
    }
    render() {
        const className = `btn ${this.props.className} ${(this.props.loading ? 'disabled' : '')}`;
        const inner = (this.props.loading) ? (
            <Icon icon="circle-o-notch" spin />
        ) : this.props.children;
        if (this.props.form === '')
            return (
                <button type={this.props.type} id={this.id} className={className} onClick={this.props.onClick} style={this.props.style}>{inner}</button>
            );
        else
            return (
                <button type={this.props.type} id={this.id} className={className} onClick={this.props.onClick} style={this.props.style} form={this.props.form}>{inner}</button>
            );
    }
    componentDidMount() {
        const el = document.getElementById(this.id);
        el.style['min-width'] = `${el.offsetWidth}px`;
    }
}

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    form: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object
}
Button.defaultProps = {
    type: 'button',
    className: 'btn btn-primary',
    form: '',
    loading: false,
    onClick: () => { },
    style: {}
}

export default Button;
import React from "react";
import { Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../pages/Login";
import Panel from "../components/Panel";

class NonAuthenticatedRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { red: false };
    }
    linkClick = (link) => {
        this.setState({ red: link });
    }
    languageChange = (language) => {
        localStorage.setItem('language', language);
        this.setState({
            language: language,
        });
    }
    render() {
        return (
            <Panel onLangChange={this.languageChange} linkClick={this.linkClick}>
                <Router>
                    {
                        (this.state.red !== false) ? (
                            <Redirect to={this.state.red} />
                        ) : ('')
                    }
                    <Switch>
                        <Route path="/login" exact>
                            <Login linkClick={this.linkClick} />
                        </Route>
                        <Route path="/">
                            <Redirect to="/login" />
                        </Route>
                    </Switch>
                </Router>
            </Panel>
        );
    }
};
export default NonAuthenticatedRoutes;
import $ from "jquery";
import getLanguage from "../conf/getLanguage";
import { Notification } from "rsuite";

const sendReq = (path, data = {}, formData = false, d = {}) => {
    return new Promise((resolve, revoke) => {
        const serviceURL = process.env.REACT_APP_SERVICE;
        const Token = localStorage.getItem('Token');
        if (!('eNot' in d)) d['eNot'] = true;
        if (!('eLang' in d)) d['eLang'] = {}
        var fData;
        if (formData) fData = data;
        else {
            fData = new FormData();
            for (let i in data) {
                var d1 = data[i];
                if (typeof (d1) !== 'object') {
                    fData.append(i, d1);
                    continue;
                }
                for (let i2 in d1) {
                    var d2 = d1[i2];
                    fData.append(i + '[' + i2 + ']', d2);
                }
            }
        }
        fData.append('Token', Token);
        $.ajax({
            xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function (evt) {
                    if (evt.lengthComputable) {
                        var percentComplete = evt.loaded / evt.total;
                        if ('uploadPrg' in d)
                            d.uploadPrg(Math.floor(percentComplete * 100));
                    }
                }, false);
                return xhr;
            },
            url: `${serviceURL}${path}`,
            method: "POST",
            processData: false,
            contentType: false,
            data: fData,
            cache: false
        }).done((res) => {
            if ((!res.status) && (res.Message === 'athentication_error')) {
                localStorage.removeItem('Token');
                window.location = '/login';
            }
            else if (res.status) resolve(res.Result);
            else {
                const mes = res.Message;
                if (d.eNot === true) {
                    const lg = { ...getLanguage('_errors'), ...d.eLang };
                    if (mes in lg)
                        Notification.error({
                            title: lg['error'],
                            description: lg[mes]
                        });
                }
                revoke(mes);
            }
        });
    })
}
export default sendReq;
import React from "react";
import Row from "../components/form/Row";
import sendReq from "../functions/sendReq";
import Loader from "../components/_common/Loader";
import getLanguage from "../conf/getLanguage";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.users = [];
        this.state = { date: new Date(),  loading: true };
        this.lang=getLanguage("formLanguage");
    }
    getUsers() {
        sendReq("users/list").then((r) => {
            
            this.users = r;
            this.setState({ date: new Date(),loading: false})
        });
        
    }
    componentDidMount() {
        this.getUsers();
        
    }
    render() {
        const mainCallBackFunction= (childData) =>{
            this.setState({ date: new Date(),loading: childData})
            
        }

        return (
            <div>
                {
                    
        console.log(this.users.map)
                }
                <Loader loading={this.state.loading}/>
          
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">User</th>
                        <th scope="col">Current HR</th>
                        <th scope="col">New HR</th>
                        <th scope="col">Date</th>
                        <th scope="col">Coin</th>
                        <th scope="col">Management Fee</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>


                    {
                        this.users.map((e, key) => (
                            <Row user={e} key={key} callback={mainCallBackFunction}  getUserProps={()=>this.getUsers()} lang={this.lang}/>
                        ))
                    }
                </tbody>
            </table>
            </div>


        )
    }

}



export default Form;
const languages = ['en-us'];
const defaultLanguage="en-us";
const getLanguage = (pg) => {
    let language = localStorage.getItem('language');
    if ((language == null)||(languages.indexOf(language)===-1)) {
        language = defaultLanguage;
        localStorage.setItem('language', defaultLanguage);
    }
    let lg = require(`../languages/${language}/${pg}.json`);
    return lg;
}
export default getLanguage;
import React from "react";
import PropTypes from "prop-types";
import sendReq from "../../functions/sendReq";
import { CheckPicker, DatePicker, Notification } from "rsuite";
import Button from "../_common/Button";
import { formatObject } from "../../functions/formatDate";
import $ from "jquery";

class AddExpInc extends React.Component {
    constructor(props) {
        super(props);
        this.users = [];
        this.selectedUsers = [];
        this.selectedDate = '';
        this.state = {
            date: new Date(),
            loading: false
        }
    }
    getUsers() {
        sendReq("adminBoard/getUsers").then((r) => {
            this.users = r;
            this.setState({ date: new Date() })
        })
    }
    render() {
        if (!this.props.addType) return null;
        const r = {
            1: {
                title: 'Add an expense'
            },
            5: {
                title: 'Add a payout'
            }
        }
        const tr = r[this.props.addType]
        const onFormSubmit = (e) => {
            e.preventDefault();
            this.setState({ loading: true })
            const frmDat = new FormData(e.target);
            frmDat.append('date', formatObject(this.selectedDate, 'YmdHis'));
            frmDat.append('type', this.props.addType);
            for (let a of this.selectedUsers)
                frmDat.append('users[]', a);
            sendReq('adminBoard/addExpInc', frmDat, true).then((r) => {
                Notification.success({
                    title: 'Success',
                    description: 'Operation success'
                })
                this.selectedUsers = [];
                this.selectedDate = '';
                $('[name="usd"]').val('');
                $('[name="value"]').val('');
                this.setState({ date: new Date() })
                this.props.onSuccess();
            }).finally(() => this.setState({ loading: false }))
        }
        return (<div className="card card-body">
            <h3 className="card-title">{tr.title}</h3>
            <form onSubmit={onFormSubmit}>
                <div className="form-group">
                    <label>Value</label>
                    <input type="text" className="form-control" name="value" />
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"> 1 ETH = </span>
                        </div>
                        <input type="text" className="form-control" name="usd" />
                        <div class="input-group-append">
                            <span class="input-group-text"> USD </span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Users</label>
                    <CheckPicker block data={this.users} onChange={(e) => this.selectedUsers = e} defaultValue={this.selectedUsers} />
                </div>
                <div className="form-group">
                    <label>Date</label>
                    <DatePicker block onChange={(e) => { this.selectedDate = e }} defaultValue={this.selectedDate} />
                </div>
                <Button loading={this.state.loading} type="submit">Add</Button>
            </form>
        </div>);
    }
    componentDidMount() {
        this.getUsers();
    }
    componentDidUpdate() {
        console.log(this.props.addType);
    }
}

AddExpInc.propTypes = {
    addType: PropTypes.any,
    onSuccess: PropTypes.func
}
AddExpInc.defaultProps = {
    addType: false,
    onSuccess: () => { }
}

export default AddExpInc;
import React from "react";
import MiniTable from "../components/_common/MiniTable";
import { formatYMDHIS, formatObject } from "../functions/formatDate";
import { DatePicker } from "rsuite";
import Button from "../components/_common/Button";
import sendReq from "../functions/sendReq";

class Incomes extends React.Component{
	constructor(props){
		super(props);
		this.state={
			date: new Date(),
			loading: false
		}
		this.date=this.state.date;
	}
	render(){
		const onSubmit=(e)=>{
			e.preventDefault();
			this.setState({loading: true});
			var frmData=new FormData(e.target);
			frmData.append('date', formatObject(this.date, 'YmdHis'));
			sendReq("incomes/add", frmData, true).then(()=>{
				this.setState({ date: new Date() })
			}).finally(()=>this.setState({loading: false}))
		}
		return(<>
			<div className="card card-body">
				<div className="card-header d-flex justify-content-between">
					<h6 className="lh-5 mg-b-0">Add</h6>
				</div>
				<form className="row" onSubmit={onSubmit}>
					<div className="form-group col-12 col-md-6">
						<label>Value</label>
						<input type="text" className="form-control" name="value"/>
					</div>
					<div className="form-group col-12 col-md-6">
						<label>Date</label>
						<DatePicker onChange={(d)=>this.date} defaultValue={this.date} block />
					</div>
					<div className="form-group col-12">
						<label>Description</label>
						<textarea maxLength="500" className="form-control" name="desc"></textarea>
					</div>
					<div className="col-12">
						<Button loading={this.state.loading} type="submit">Send</Button>
					</div>
				</form>
			</div>
				<MiniTable
					title="Pool Awards"
					service="incomes/list"
					showEmpty={true}
					date={this.state.date}
					columns={[
						{
							title: "Date", width: "40", fn: (a) => {
								return formatYMDHIS(a.Date, 'm/d/Y H:i')
							}
						},
						{
							title: "Amount", width: "40", cl: 'text-right', fn: (a) => {
								return (parseInt(a.Value / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ETH'
								}
						},
						{
							title: "Description", width: "60", col: "Description"
						}
					]}
				/>
		</>);
	}
	componentDidMount(){
	}
}

export default Incomes;
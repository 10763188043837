import React from "react";
import Loader from "../_common/Loader";
import sendReq from "../../functions/sendReq";
import { Bar } from 'react-chartjs-2';

class PayoutChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            loading: true
        }
        this.r = {};
        this.options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };
    }
    getPayouts() {
        sendReq('dashboard/payoutChart')
            .then((r) => this.r = r)
            .finally(() => this.setState({ loading: false }))
    }
    render() {
        return (
            <div className="card ht-lg-100p">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h6 className="mg-b-0">Payout Chart</h6>
                </div>
                <div className="card-body pd-0">
                    <div className="pd-y-25 pd-x-20 hycoc-mx" style={{ height: "500px", position: "relative" }}>
                        {this.renderChart()}
                    </div>
                </div>
            </div>
        );
    }
    renderChart() {
        if (this.state.loading) return (
            <div style={{ height: "500px", position: "relative" }}>
                <Loader loading={true} height="500px" />
            </div>
        )
        return (
            <Bar data={this.r} options={this.options} />
        )
    }
    componentDidMount() {
        this.getPayouts();
    }
}

export default PayoutChart;
import React from "react";
import { Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import AdminBoard from "../pages/AdminBoard";
import Incomes from "../pages/Incomes";
import Form from "../pages/Form";
import Panel from "../components/Panel";
import { createBrowserHistory } from "history";
import { Guid } from '../functions/userInfos';


class AuthenticatedRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { red: false };
    }
    languageChange = (language) => {
        localStorage.setItem('language', language);
        this.setState({
            language: language,
        });
    }
    linkClick = (link) => {
        this.setState({ red: link });
    }
    render() {
        const history = createBrowserHistory();
        console.log(history);
        return (
            <Panel onLangChange={this.languageChange} linkClick={this.linkClick} loggedIn={true}>
                <Router>
                    {
                        (this.state.red !== false) ? (
                            <Redirect to={this.state.red} />
                        ) : ('')
                    }
                    <Switch>
                        <Route path="/" exact>
                            <Dashboard linkClick={this.linkClick} />
                        </Route>
                        <Route path="/form" exact>
                            <Form linkClick={this.linkClick} />
                        </Route>
                        {(Guid==='C08E7ED3-DF7B-BCF8-0C53-25422FE9F694') ? (<>
                            <Route path="/admin-board" exact>
                                <AdminBoard linkClick={this.linkClick} />
                            </Route>
                            <Route path="/incomes" exact>
                                <Incomes linkClick={this.linkClick} />
                            </Route>
                        </>) : ''}
                        <Route path="/login">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </Router>
            </Panel>
        );
    }
};
export default AuthenticatedRoutes;
import React from "react";
import SimpleBar from "simplebar-react";
import sendReq from "../../functions/sendReq";
import Loader from "../_common/Loader";
import PropTypes from 'prop-types';
import Button from "../_common/Button";
import { dateEqual } from "../../functions/formatDate";

class MiniTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            loading: true,
            loadMore: true
        }
        this.page = 1;
        this.quantity = props.quantity;
        this.data = []
        this.refDate = props.date;
    }
    loadData() {
        this.setState({ loading: true });
        sendReq(this.props.service, {
            page: this.page,
            quantity: this.quantity
        }).then((r) => {
            if (r.length !== this.quantity) this.setState({ loadMore: false });
            this.data = [...this.data, ...r];
            this.setState({ loading: false })
        })
    }
    tableRender() {
        if ((this.state.loading === true) && (this.page === 1))
            return (
                <div style={{ minHeight: this.props.innerHeight }}>
                    <Loader loading={true} height={this.props.innerHeight} />
                </div>
            );
        const loadMore = () => {
            this.page++;
            this.loadData();
        }
        return (
            <SimpleBar style={{ maxHeight: this.props.innerHeight }}>
                <table className="table table-borderless table-dashboard table-dashboard-one table-hover">
                    <thead>
                        <tr>
                            {
                                this.props.columns.map((e, key) => {
                                    const cl = ('cl' in e) ? e.cl : '';
                                    return (
                                        <th key={key} className={`wd-${e.width} ${cl}`}>{e.title}</th>
                                    );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.data.map((e, key) => {
                                return (
                                    <tr key={key} style={{ cursor: 'pointer' }} onClick={() => this.props.rowClick(e)}>
                                        {
                                            this.props.columns.map((c, key) => {
                                                const cl = ('cl' in c) ? c.cl : '';
                                                if ('fn' in c)
                                                    return (
                                                        <td className={cl} key={key}>
                                                            {c.fn(e)}
                                                        </td>
                                                    );
                                                else
                                                    return (
                                                        <td className={cl} key={key}>
                                                            {e[c.col]}
                                                        </td>
                                                    );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                {
                    (this.state.loadMore) ? (
                        <Button
                            className="btn-primary btn-block"
                            loading={this.state.loading}
                            onClick={loadMore}
                        >Load More</Button>
                    ) : null
                }
                {
                    (this.data.length===0) ? (
                        <h5 className="mg-l-20 text-muted">Sorry. No data to show</h5>
                    ) : ''
                }
            </SimpleBar>
        );
    }
    render() {
        if (this.page === 1 && JSON.stringify(this.data) === '[]' && !this.props.showEmpty) return null;
        const onRefresh = () => {
            this.page = 1
            this.data = []
            this.setState({ date: new Date(), loadMore: true })
            this.loadData()
        }
        return (
            <>
                <div className="card ht-lg-100p">
                    <div className="card-header d-flex justify-content-between">
                        <h6 className="lh-5 mg-b-0">{this.props.title}</h6>
                        <ul class="list-inline d-flex mg-t-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
                            <li className="list-inline-item d-flex align-items-center">
                                <button className="tx-11 link-03" onClick={onRefresh}>
                                    <i className="ion-md-refresh"></i>
                                </button>
                            </li>

                            {
                                (!this.props.onAddClick) ? '' : (
                                    <li className="list-inline-item d-flex align-items-center">
                                        <button className="tx-11 link-03" onClick={this.props.onAddClick}>
                                            <i className="ion-md-add"></i>
                                        </button>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="card-body pd-0">
                        <div className="pd-y-25 pd-x-20">
                            {this.tableRender()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate() {
        if (dateEqual(this.props.date, this.refDate)) return;
        this.page = 1;
        this.data = [];
        this.refDate = this.props.date;
        this.loadData();
    }
}

MiniTable.propTypes = {
    title: PropTypes.string,
    service: PropTypes.string,
    rowClick: PropTypes.func,
    innerHeight: PropTypes.string,
    columns: PropTypes.array,
    quantity: PropTypes.number,
    showEmpty: PropTypes.bool,
    background: PropTypes.number,
    onAddClick: PropTypes.any,
    date: new Date()
}
MiniTable.defaultProps = {
    title: "",
    service: "topZips",
    rowClick: () => { },
    innerHeight: "400px",
    columns: [],
    quantity: 20,
    showEmpty: false,
    background: 1,
    onAddClick: false,
    date: new Date()
}

export default MiniTable;
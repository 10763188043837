import React from "react";
import MiniTable from "../components/_common/MiniTable";
import { formatYMDHIS } from "../functions/formatDate";
import sendReq from "../functions/sendReq";
import Loader from "../components/_common/Loader";
import AddExpInc from "../components/adminBoard/AddExpInc";

class AdminBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            date: new Date(),
            add: false,
            pytDate: new Date(),
            expDate: new Date(),
            cr: 0
        }
    }
    getCurrencies() {
        sendReq('adminBoard/getCurrencies').then((r) => {
            this.cr = r;
        }).finally(() => this.setState({ loading: false }));
    }
    render() {
        if (this.state.loading) return (<Loader loading={true} height="100vh" />)
        const onSuccess=()=>{
            if(this.state.add===1) this.setState({expDate: new Date()})
            else this.setState({pytDate: new Date()})
        }
        const cr = this.cr;
        const kys = Object.keys(cr);
        return (<div className="row">
            <div className="col-12 mg-t-10">
                <AddExpInc addType={this.state.add} onSuccess={onSuccess} />
            </div>
            <div className="col-12 col-md-6 mg-t-10">
                <MiniTable
                    title="Expenses"
                    service="/adminBoard/expenses"
                    showEmpty={true}
                    onAddClick={() => this.setState({ add: 1 })}
                    date={this.state.expDate}
                    columns={[
                        {
                            title: "Date", width: "60", fn: (a) => {
                                return formatYMDHIS(a.Date, 'm/d/Y H:i')
                            }
                        },
                        {
                            title: "User", width: "30", col: "usr"
                        },
                        {
                            title: "Amount", width: "40", cl: 'text-right', fn: (a) => {
                                return (parseInt(a.Value * cr[kys[this.state.cr]] / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ' + kys[this.state.cr]
                            }
                        }
                    ]}
                />
            </div>
            <div className="col-12 col-md-6 mg-t-10">
                <MiniTable
                    title="Payouts"
                    service="/adminBoard/payouts"
                    showEmpty={true}
                    onAddClick={() => this.setState({ add: 5 })}
                    date={this.state.pytDate}
                    columns={[
                        {
                            title: "Date", width: "60", fn: (a) => {
                                return formatYMDHIS(a.Date, 'm/d/Y H:i')
                            }
                        },
                        {
                            title: "User", width: "30", col: "usr"
                        },
                        {
                            title: "Amount", width: "40", cl: 'text-right', fn: (a) => {
                                return (parseInt(a.Value * cr[kys[this.state.cr]] / Math.pow(10, 8)) / Math.pow(10, 10)) + ' ' + kys[this.state.cr]
                            }
                        }
                    ]}
                />
            </div>
        </div>);
    }
    componentDidMount() {
        this.getCurrencies();
    }
}

export default AdminBoard